<template>
  <div class="benvenuto">
    <h1>Benvenuto, questa è la Mobile Platform ...</h1>
    <v-img :src="`${publicPath}img/ubisell-logo.png`" max-height="50vh" contain eager/>
    <h2>Abilita la licenza per accedere ai tuoi dati online</h2>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style scoped>
  .benvenuto {
    display: block;
    width: 100vw;
    height: 98vh;
    text-align: center;
    box-sizing: border-box;
    padding-top: 10vh;
  }
</style>
